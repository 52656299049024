import { useTSE } from "~/composables/useTSE";

export function useOrder() {
  async function createOrder(shopId, clientId, userId = null) {
    const { $repository, globalStore, toast, router } = useNuxtApp();
    const { fiskalyClient, tseModal, updatedTSECallback } = useTSE();

    globalStore.isLoading = true;
    const request = await $repository.order.createOrder(shopId, clientId, userId, fiskalyClient.value?.id);
    if (request && request.success) {
      location.href = `/orders/${request.order.id}`;
    } else {
      if (request.info === "Buchung ist TSE Pflichtig") {
        // Nach TSE Client Auswahl soll er die Buchung erzeugen
        tseModal.value.open();
        updatedTSECallback.value = function () {
          tseModal.value.close();
          createOrder(shopId, clientId, userId);
          updatedTSECallback.value = null;
        };
      } else toast.showError("Buchung erstellen", request.info);

      globalStore.isLoading = false;
    }
  }

  return { createOrder };
}
