import { getClient } from "~/composables/admin";

export function useTSE() {
  const tseModal = useState("tse_modal");
  const fiskalyClient = useState("fiskaly_client");
  const cookieFiskalyClientID = useCookie("fiskaly_client_id");
  const updatedTSECallback = useState("updated_tse_callback");
  async function loadFiskalyClient() {
    if (cookieFiskalyClientID.value) {
      const client = await getClient();
      fiskalyClient.value = client.value.fiskaly_clients.find(_fiskalyClient => _fiskalyClient.id === Number.parseInt(cookieFiskalyClientID.value));
    } else fiskalyClient.value = null;
  }

  async function updateFiskalyClient(_id) {
    cookieFiskalyClientID.value = _id;
    await loadFiskalyClient();

    // Es gibt verschiedene Aktionen die nach dem Update eintreten können, wie z.B. das die Buchung erzeugt werden soll
    if (!updatedTSECallback.value) tseModal.value.close();
    else updatedTSECallback.value();
  }

  return { tseModal, fiskalyClient, updatedTSECallback, updateFiskalyClient, loadFiskalyClient };
}
